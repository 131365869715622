body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header > ul{
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  background-color: #007bff;
}
header > ul > li{
  flex-basis: calc(100%/4);
  width: calc(100%/4);
}

header > ul > li > a{
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

header > ul > li > a:hover, header > ul > li > a:focus{
  background-color: #023368;
  color: white;
}

body{
  background: rgb(14,0,255);
  background: linear-gradient(90deg, rgba(14,0,255,1) 0%, rgba(86,86,176,1) 0%, rgba(0,212,255,1) 100%);
  color: white;
}
.container{
  width: 100%;
  height: 100%;
}

.row{
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  margin-top: 50px;
}

.col-12{
  flex-basis: 100%;
  width: 100%;
}

.col-6{
  flex-basis: calc(100%/2);
  width: calc(100%/2);
}

.col-4{
  flex-basis: calc(100%/3);
  width: calc(100%/3);
}

.col-4{
  flex-basis: calc(100%/4);
  width: calc(100%/4);
}

.list-container{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.list-container > li > a{
  color: white;
  text-decoration: none;
}

.list-container > li > a:hover{
  color: blue;
}

.list-container > li{
  transition: 0.5s;
}

.list-container > li:hover{
  transition: 0.5s;
  transform: scale(1.1, 1.1);
}

.list-deco-container{
  color: white;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.btn{
  width: auto;
  height: 25px;
  color: #007bff;
  background-color: white;
  background-image: none;
  border-color: #007bff;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 5px ;
  margin: 5px;
}

.btn:hover{
  color: white;
  background-color: #007bff;
}

.inpt{
  height: 22.5px;
  border-radius: 5px;
}


.result > a{
  color: white;
}

.result > a:hover{
  color: blue;
}